import React from "react"
import { Link } from "gatsby"


const AGBContent = () => {
  return (
    <section className="terms-of-service-area ptb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="terms-of-service-content">
              <h2>Allgemeine Geschäftsbedingungen (AGB) der LOGENTIS GmbH</h2>
              <p>Stand: 13.03.2025</p>

              <h3>1. Geltung der allgemeinen Geschäftsbedingungen der Firma LOGENTIS GmbH</h3>

              <p> Diese allgemeinen Geschäfts− und Lieferbedingungen gelten für alle zwischen der Firma und dem
                Käufer abgeschlossenen Verträge sowie alle sonstigen Absprachen, die im Rahmen der
                Geschäftsverbindung getroffen werden. Allgemeine Geschäftsbedingungen des Kunden werden
                ausdrücklich nicht Vertragsinhalt, auch wenn ihnen seitens der Firma nicht ausdrücklich
                widersprochen wird. Für den Fall, dass der Kunde die nachfolgenden allgemeinen Geschäfts− und
                Lieferbedingungen nicht gelten lassen will, hat er dies vorher schriftlich der Firma anzuzeigen.
                Eine Beauftragung gilt als vorbehaltlose Anerkennung dieser allgemeinen Geschäftsbedingungen.</p>

              <h3>2. Zahlungsbedingungen und Preise</h3>

              <p> Alle Rechnungen der Firma sind innerhalb von 14 (vierzehn) Tagen ab Rechnungsdatum zahlbar.
                Maßgebend ist das Datum des Eingangs der Zahlung bei der Firma.
                Sofern keine einzelvertraglichen Regelungen vorliegen, sind die Zahlungen sofort fällig und ohne
                jeden Abzug auf das Konto der Firma zu leisten, und zwar 40% der Auftragssumme als Anzahlung
                nach Eingang der Auftragsbestätigung, sowie 60% der Auftragssumme nach Go-Live/Produktivstart.
                Entsprechende Rechnungsstellung erfolgt durch die Firma.
                Bei Zahlungsverzug des Kunden ist die Firma berechtigt, Zinsen in Höhe von 5 % über dem jeweils
                gültigen Basiszinssatz zu berechnen. Alle Preise verstehen sich zuzüglich der jeweiligen gesetzlichen
                Mehrwertsteuer. Die Firma ist berechtigt, Teillieferungen vorzunehmen.</p>

              <h3>3. Lieferung und Versand</h3>

              <p> Alle Angebote sind freibleibend. Lieferung erfolgt nur, solange der Vorrat reicht. Alle von der Firma
                genannten Liefertermine sind unverbindliche Liefertermine, es sei denn, dass ein Liefertermin
                ausdrücklich schriftlich bindend vereinbart wird. Verlangt der Käufer nach Auftragserteilung
                Änderungen oder Ergänzungen des Auftrages oder treten sonstige Umstände ein, die der Firma eine
                Einhaltung des Liefertermins unmöglich machen, obwohl die Firma diese Umstände nicht zu vertreten
                hat, so verschiebt sich der Liefertermin um einen angemessenen Zeitraum. Wird die Firma an der
                rechtzeitigen Vertragserfüllung, z. B. durch Beschaffungs−, Fabrikations− oder Lieferstörungen bei ihr
                oder bei ihrem Zulieferanten gehindert, so gelten die allgemeinen Rechtsgrundsätze mit der
                Maßgabe, dass der Kunde nach Ablauf von einem Monat eine Nachfrist von sechs Wochen setzen
                kann. Ist die Nichteinhaltung eines verbindlichen Liefertermins nachweislich auf Mobilmachung,
                Krieg, Aufruhr, Streik oder Aussperrung oder auf sonstige nach allgemeinen Rechtsgrundsätzen von
                der Firma nicht zu vertretende Umstände zurückzuführen, so wird die Lieferfrist angemessen
                verlängert. Der Kunde kann vom Vertrag zurücktreten, wenn er der Firma nach Ablauf der
                verlängerten Frist eine angemessene Nachfrist setzt. Der Rücktritt hat schriftlich zu erfolgen, wenn
                die Firma nicht innerhalb der Nachfrist erfüllt. Wird der Firma die Vertragserfüllung aus den
                vorgenannten Gründen ganz oder teilweise unmöglich, so wird sie von ihrer Lieferpflicht frei. Die
                Kosten für den Versand und die Transportversicherung sind grundsätzlich vom Kunden zu tragen,
                wobei die Wahl des Versandweges und der Versandart im freien Ermessen der Firma liegt. Der Kunde
                ist verpflichtet, die Ware beim Eintreffen sofort zu untersuchen und erkennbare Transportschäden
                sowie jegliche Beschädigung der Verpackung unverzüglich schriftlich der Firma zu melden. Gleiches
                gilt für verdeckte Schäden. Geht die Firma aufgrund des Unterlassens dieser Verpflichtung ihrer
                Ansprüche gegenüber der Versicherung oder dem Sublieferanten verlustig, so haftet der Kunde für
                sämtliche Kosten, die aus dieser Obliegenheitsverletzung resultieren. Die Gefahr geht auf den Kunden
                über, sobald die Ware das Werk oder das Lager der Firma verlässt.</p>

              <h3>4. Eigentumsvorbehalt</h3>

              <p> Die gelieferte Ware bleibt bis zur vollständigen Bezahlung sämtlicher Forderungen der Firma aus der
                Geschäftsverbindung mit dem Kunden in Haupt− und Nebensache Eigentum der Firma. Der Kunde ist
                verpflichtet, die unter dem Eigentumsvorbehalt der Firma stehenden Sachen ordnungsgemäß zu
                versichern (d. h. Diebstahl−, Feuer−, Wasser− und Schwachstromversicherung) und der Firma auf
                Anforderung eine solche Versicherung nachzuweisen. Im Schadensfall gilt der Versicherungsanspruch
                des Kunden als an die Firma abgetreten. Der Kunde ist zur Verfügung über die unter dem
                Eigentumsvorbehalt stehenden Sachen nicht befugt. Bei Pfändungen oder Beschlagnahmen hat der
                Kunde die Firma unverzüglich schriftlich zu unterrichten und hat Dritte auf den Eigentumsvorbehalt
                der Firma unverzüglich in geeigneter Form hinzuweisen. Für den Fall, dass der Kunde dennoch die
                Liefergegenstände veräußert und die Firma dieses genehmigen sollte, tritt der Kunde der Firma
                bereits mit Vertragsabschluss alle Ansprüche gegen seine Abnehmer ab. Der Kunde ist verpflichtet,
                der Firma alle zur Geltendmachung dieser Rechte erforderlichen Informationen herauszugeben und
                die erforderlichen Mitwirkungshandlungen zu erbringen.</p>

              <h3>5.Haftungsbeschränkung</h3>

              <p> Die Firma haftet bei Vorsatz und grober Fahrlässigkeit nach den gesetzlichen Vorschriften. Bei
                leichter
                Fahrlässigkeit haftet die Firma nur, wenn eine wesentliche Vertragspflicht (Kardinalspflicht) verletzt
                wird oder ein Fall des Verzugs oder der Unmöglichkeit vorliegt. Im Fall einer Haftung aus leichter
                Fahrlässigkeit wird diese Haftung auf solche Schäden begrenzt, die vorhersehbar bzw. typisch sind.
                Eine Haftung für das Fehlen garantierter Eigenschaften, wegen Arglist, für Personenschäden,
                Rechtsmängel, nach dem Produkthaftungsgesetz und dem Bundesdatenschutzgesetz bleibt
                unberührt. Im Falle einer Inanspruchnahme der Firma aus Gewährleistung oder Haftung ist ein
                Mitverschulden des Kunden angemessen zu berücksichtigen, insbesondere bei unzureichenden
                Fehlermeldungen oder unzureichender Datensicherung. Unzureichende Datensicherung liegt
                insbesondere dann vor, wenn der Kunde es versäumt hat, durch angemessene, dem Stand der
                Technik entsprechende Sicherungsmaßnahmen gegen Einwirkungen von außen, insbesondere gegen
                Computerviren und sonstige Phänomene, die einzelne Daten oder einen gesamten Datenbestand
                gefährden können, Vorkehrungen zu treffen.</p>

              <h3>6. Gewährleistung für Hardware</h3>

              <p>Die Firma gewährleistet, dass die Waren nicht mit Mängeln behaftet sind, die den Wert oder die
                Tauglichkeit zu dem gewöhnlichen oder nach dem Vertrag vorausgesetzten Gebrauch aufheben oder
                mindern. Die Firma und der Kunde sind sich darüber einig, dass im Handbuch und/oder in der
                Preisliste enthaltene Erklärungen und Beschreibungen sowohl der Hard− als auch der Software keine
                Zusicherung bestimmter Eigenschaften darstellen. Die Gewährleistungsfrist beträgt zwölf Monate und
                beginnt mit dem Tag der Lieferung. Ist der Kunde ein Verbraucher im Sinn des Bürgerlichen
                Gesetzbuchs, so beträgt die Gewährleistungsfrist zwei Jahre. Während der Gewährleistungsfrist
                auftretende Mängel hat der Kunde der Firma unverzüglich schriftlich zu melden. Die Gewährleistung
                umfasst nicht die Beseitigung von Mängeln, die durch normalen Verschleiß, äußere Einflüsse oder
                Bedienungsfehler entstehen. Die Gewährleistung entfällt, soweit der Kunde ohne Zustimmung der
                Firma Geräte, Elemente oder Zusatzeinrichtungen selbst ändert oder durch Dritte ändern lässt, es sei
                denn, dass der Kunde den vollen Nachweis führt, dass die noch in Rede stehenden Mängel weder
                insgesamt noch teilweise durch solche Änderungen verursacht worden sind und dass die
                Mängelbeseitigung durch die Änderung nicht erschwert wird.
                Erweist sich die Mängelrüge als berechtigt, setzt der Kunde der Firma eine angemessene Frist zur
                Nacherfüllung. Der Kunde teilt der Firma mit, welche Art der Nacherfüllung bzw. Verbesserung der
                gelieferten oder Lieferung einer neuen, mangelfreien Sache − er wünscht. Die Firma ist jedoch
                berechtigt, die gewählte Nacherfüllung zu verweigern, wenn diese nur mit unverhältnismäßigen
                Kosten für sie durchgeführt werden kann und wenn die andere Art der Nacherfüllung keine
                erheblichen Nachteile für den Kunden mit sich bringen würde. Die Firma kann außerdem die
                Nacherfüllung insgesamt verweigern, wenn sie nur mit unverhältnismäßigen Kosten für sie
                durchführbar ist.
                Zur Durchführung der Nacherfüllung für denselben oder in direktem Zusammenhang stehenden
                Mangel stehen der Firma zwei Versuche innerhalb der vom Kunden gesetzten Frist zu. Nach dem
                zweiten fehlgeschlagenen Nacherfüllungsversuch kann der Kunde vom Vertrag zurücktreten oder
                mindern. Das Rücktritts− bzw. Minderungsrecht kann bereits nach dem ersten erfolglosen
                Nacherfüllungsversuch ausgeübt werden, wenn ein zweiter Versuch innerhalb der gesetzten Frist dem
                Kunden nicht zuzumuten ist. Wenn die Nacherfüllung unter den oben ausgeführten Voraussetzungen
                verweigert wurde, steht dem Kunden das Minderungs− bzw. Rücktrittsrecht sofort zu.
                Der Rücktritt wegen eines unerheblichen Mangels ist ausgeschlossen. Tritt ein Mangel auf, der Folge
                eines nicht korrekten oder nicht aktualisierten Treibers ist, so räumt der Kunde der Firma das Recht
                ein, einen funktionablen Treiber, binnen 10 Tagen ab Mitteilung an die Firma, nachzuliefern. Hat der
                Kunde die Firma wegen Gewährleistung in Anspruch genommen und stellt sich heraus, dass entweder
                kein Mangel vorhanden ist oder der geltend gemachte Mangel die Firma nicht zur Gewährleistung
                verpflichtet, so hat der Kunde, sofern er die Inanspruchnahme der Firma grob fahrlässig oder
                vorsätzlich zu vertreten hat, allen der Firma entstandenen Aufwand zu ersetzen. Die Lieferung einer
                Bedienungsanleitung in englischer Sprache ist zulässig, wenn der Vertragsgegenstand noch nicht für
                den jeweiligen Markt vollständig lokalisiert ist. Gleiches gilt, wenn der Vertragsgegenstand generell
                nur in englischsprachiger Version lieferbar ist.</p>

              <h3>7. Gewährleistung für Software</h3>

              <p> Der Kunde wird die Software unmittelbar nach der Lieferung untersuchen und dem Verkäufer
                offensichtliche Fehler schriftlich unverzüglich mitteilen. Die Firma gewährleistet für einen Zeitraum
                von zwölf Monaten ab dem Zeitpunkt der Ablieferung, dass die Software hinsichtlich ihrer
                Funktionsweise im Wesentlichen der Programmbeschreibung im begleitenden Schriftmaterial
                entspricht. Ist der Kunde ein Verbraucher im Sinn des Bürgerlichen Gesetzbuches, so beträgt die
                Gewährleistungsfrist zwei Jahre.
                Tritt ein Mangel auf, so sind in einer schriftlichen Mängelrüge der Mangel und seine
                Erscheinungsform so genau zu beschreiben, dass eine Überprüfung des Mangels (z. B. Vorlage der
                Fehlermeldungen) machbar ist und der Ausschluss eines Bedienungsfehlers (z. B. Angabe der
                Arbeitsschritte) möglich ist. Erweist sich die Mängelrüge als berechtigt, setzt der Kunde der Firma
                eine angemessene Frist zur Nacherfüllung. Der Kunde teilt der Firma mit, welche Art der
                Nacherfüllung − Verbesserung der gelieferten oder Lieferung einer neuen, mangelfreien Sache − er
                wünscht. Die Firma ist jedoch berechtigt, die gewählte Nacherfüllung zu verweigern, wenn diese nur
                mit unverhältnismäßigen Kosten für sie durchgeführt werden kann und wenn die andere Art der
                Nacherfüllung keine erheblichen Nachteile für den Kunden mit sich bringen würde. Die Firma kann
                außerdem die Nacherfüllung insgesamt verweigern, wenn sie nur mit unverhältnismäßigen Kosten für
                ihn durchführbar ist.
                Zur Durchführung der Nacherfüllung für denselben oder in direktem Zusammenhang stehenden
                Mangel stehen der Firma zwei Versuche innerhalb der vom Kunden gesetzten Frist zu. Nach dem
                zweiten fehlgeschlagenen Nacherfüllungsversuch kann der Kunde vom Vertrag zurücktreten oder
                mindern. Das Rücktritts− bzw. Minderungsrecht kann bereits nach dem ersten erfolglosen
                Nacherfüllungsversuch ausgeübt werden, wenn ein zweiter Versuch innerhalb der gesetzten Frist dem
                Kunden nicht zuzumuten ist. Wenn die Nacherfüllung unter den oben ausgeführten Voraussetzungen
                verweigert wurde, steht dem Kunden das Minderungs− bzw. Rücktrittsrecht sofort zu. Tritt ein
                Mangel auf, der Folge eines nicht korrekten oder nicht aktualisierten Treibers ist, so räumt der Kunde
                der Firma das Recht ein, einen funktionablen Treiber, binnen 10 Tagen ab Mitteilung an die Firma,
                nachzuliefern. Der Rücktritt wegen eines unerheblichen Mangels ist ausgeschlossen. Hat der Kunde
                die Firma wegen Gewährleistung in Anspruch genommen, und stellt sich heraus, dass entweder kein
                Mangel vorhanden ist oder der geltend gemachte Mangel die Firma nicht zur Gewährleistung
                verpflichtet, so hat der Kunde, sofern er die Inanspruchnahme der Firma grob fahrlässig oder
                vorsätzlich zu vertreten hat, allen ihr entstandenen Aufwand zu ersetzen. Keine Haftung wird dafür
                übernommen, dass die Software für die Zwecke des Kunden geeignet ist und mit beim Anwender
                vorhandener Software zusammenarbeitet.
                Die Lieferung von Handbüchern und Dokumentationen über das mit der Software ausgelieferte
                Schriftmaterial/Programmbeschreibung und die in die Software implementierte Benutzerführung
                und/oder Online−Hilfe hinaus, oder eine Einweisung wird nur dann geschuldet, wenn dies
                ausdrücklich schriftlich zwischen den Parteien vereinbart worden ist. Im Fall einer solchen
                ausdrücklichen Vereinbarung sind Anforderungen hinsichtlich Inhalt, Sprache und Umfang eines
                ausdrücklich zu liefernden Handbuches und/oder einer Dokumentation nicht getroffen, und die
                Lieferung einer Kurzanleitung ist ausreichend, es sei denn, dass die Parteien schriftlich weitere
                Spezifikationen vereinbart haben. Die Lieferung einer Bedienungsanleitung in englischer Sprache ist
                zulässig, wenn der Vertragsgegenstand noch nicht für den jeweiligen Markt vollständig lokalisiert ist.
                Gleiches gilt, wenn der Vertragsgegenstand generell nur in englischsprachiger Version lieferbar ist.</p>

              <h3>8. Vertraulichkeit</h3>

              <p> Die Firma und der Kunde verpflichten sich gegenseitig, alle Geschäfts− und Betriebsgeheimnisse der
                anderen Seite unbefristet geheim zu halten und nicht an Dritte weiterzugeben oder in irgendeiner
                Weise zu verwerten. Die Unterlagen, Zeichnungen und andere Informationen, die der andere
                Vertragspartner aufgrund der Geschäftsbeziehung erhält, darf dieser nur im Rahmen des jeweiligen
                Vertragszweckes nutzen.</p>

              <h3>9. Beweisklausel</h3>

              <p> Daten, die in elektronischen Registern oder sonst in elektronischer Form bei der Firma gespeichert
                sind, gelten als zulässiges Beweismittel für den Nachweis von Datenübertragungen, Verträgen und
                ausgeführten Zahlungen zwischen den Parteien.</p>

              <h3>10. Schutzrechte</h3>

              <p> Ohne ausdrückliche Genehmigung der Firma ist es dem Käufer nicht gestattet, die von der Firma
                erworbene Ware in Länder außerhalb der EG zu exportieren. Daneben hat der Käufer sämtliche
                einschlägige Exportbestimmungen, insbesondere diejenigen nach der Außenwirtschaftsverordnung
                sowie gegebenenfalls Regelungen nach US−Recht, zu beachten.</p>

              <h3>11. Export</h3>

              <p> Der Käufer erkennt an, dass der Weiterverkauf jeglicher aus den USA importierten Produkte den
                Export−Kontrollbestimmungen der Vereinigten Staaten von Amerika unterliegt, die die Ausfuhr und
                Wiedereinfuhr von Hardware, Software, technischen Datenträgern und unmittelbaren Produkten von
                technischen Datenträgern einschließlich Dienstleistungen, die im Zusammenhang mit der
                Verwendung dieser Produkte stehen, beschränken. Der Käufer ist damit einverstanden, dass er weder
                direkt noch indirekt aus den USA importierte Produkte, Informationen oder Dokumentationen, die
                damit im Zusammenhang stehen, in irgendwelche Länder bzw. an irgendwelche Endabnehmer
                exportiert oder weiterexportiert, ohne vorher die hierfür erforderliche Zustimmung von der hierfür
                zuständigen Behörde eingeholt zu haben. Erforderlich ist die Zustimmung des amerikanischen
                "Department of Commerce", Abteilung für die Verwaltung von Exportangelegenheiten, oder einer
                vergleichbaren Stelle. Dasselbe gilt für alle Verwendungen seitens des Endabnehmers, die durch
                US−Bestimmungen beschränkt sind. Diese Bestimmungen beziehen sich insbesondere auf Länder, für
                die Beschränkungen gelten: Kuba, Haiti, Restjugoslawien (Serbien und Montenegro), Iran, Irak,
                Nordkorea, Syrien und Vietnam;
                Endabnehmer, für die Beschränkungen gelten: alle Endabnehmer, von denen der Käufer weiß oder
                die begründete Vermutung hat, dass die Produkte, die aus den USA importiert wurden, für den
                Entwurf, die Entwicklung oder die Produktion von Raketen bzw. in der Raketentechnik, im
                Zusammenhang mit Nuklearwaffen oder bei chemischen und biologischen Waffen verwendet
                werden;
                Endverbrauch, für den Beschränkungen gelten: jeglicher Gebrauch von Produkten, die im
                Zusammenhang mit dem Entwurf, der Entwicklung oder der Produktion von Raketen bzw. der
                Raketentechnik, im Zusammenhang mit Nuklearwaffen oder der Waffentechnik oder für chemische
                und biologische Waffen aus den USA importiert wurden.</p>

              <h3>12. Sonstiges</h3>

              <p> Sollten einzelne Bestimmungen dieser allgemeinen Geschäftsbedingungen ganz oder teilweise
                unwirksam sein oder werden, so berührt dies die Gültigkeit der übrigen Bestimmungen nicht.
                Vielmehr tritt an die Stelle der nichtigen Bestimmungen dasjenige, was dem gewollten Zweck am
                nächsten kommt. Nebenabreden sind nicht getroffen. Vertragsergänzungen entfalten nur
                Wirksamkeit, wenn sie schriftlich bestätigt werden. Der Kunde kann seine Rechte aus einer
                Geschäftsbeziehung mit der Firma nur mit schriftlicher Einwilligung der Firma abtreten. Eine
                Aufrechnung gegenüber der Kaufpreisforderung ist dem Kunden nur mit anerkannten oder
                rechtskräftig festgestellten Gegenforderungen möglich. Gerichtsstand ist, soweit gesetzlich zulässig,
                der Sitz der Firma (Hauptniederlassung) in der Bundesrepublik Deutschland. Es gilt ausschließlich
                deutsches Recht.
              </p>
              <p><a href={"/dl/Logentis_AGB.pdf"}>Download</a> AGB der LOGENTIS GmbH (0,1 Mb)</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-12">
            <aside className="widget-area">
              <div className="widget widget_insight">
                <ul>
                  <li>
                    <Link to="/impressum/">
                      Impressum
                    </Link>
                  </li>
                  <li className="active">
                    <Link to="/agb/">
                      AGB
                    </Link>
                  </li>
                  <li>
                    <Link to="/datenschutz/">
                      Datenschutz
                    </Link>
                  </li>
                </ul>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AGBContent
