import Layout from "../components/App/Layout"
import Seo from "../components/App/Seo"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import { graphql } from "gatsby"
import React from "react"
import AGBContent from "../components/AboutUs/AGBContent"

const AGBPage = ({ data, location: { origin } }) => {
  return (
    <Layout>
      <Seo title={"Die AGB der LOGENTIS GmbH"}
           description={"Die offiziellen LOGENTIS AGB inkl. Link zum " +
             "Download."}
           image={"/images/og/logentis-main-og.png"}
      >
      </Seo>
      <Navbar />
      <PageBanner
        pageTitle="AGB"
        homePageText="Home"
        homePageUrl="/"
        activePageText="AGB"
      />
      <AGBContent />
      <Footer />
    </Layout>
  )
}


export default AGBPage

export const pageQuery = graphql`
query {
    site {
      siteMetadata {
        siteUrl
      }
    }
}
`
